.content {
  background: var(--foreground-rgb);
  color: var(--app-text-color);
  margin-top: 50px;
  padding: 30px 0;

  > div:nth-of-type(1) {
    text-align: center;
    font-size: 48px;
    color: var(--app-text-color);
    margin-bottom: 50px;

    > div:nth-of-type(1) {
      font-family: CrimsonText-Italic, serif;
    }

    > div:nth-of-type(2) {
      margin-top: 10px;
      font-size: 18px;
      line-height: 1;
    }
  }

  .mainCon {
    > div {
      margin-bottom: 80px;
    }

    > .firstRow {
      display: flex;

      > .left {
        flex-shrink: 0;
        flex: 0.45;
        > div:nth-of-type(1) {
          color: #68f3f6;
          font-size: 45px;
          font-family: 'CrimsonText-Italic', serif;
        }

        > div:nth-of-type(2) {
          font-size: 45px;
          font-family: CrimsonText-Italic, serif;
        }

        > div:nth-of-type(3) {
          font-size: 12px;
        }
      }

      > .right {
        flex-shrink: 0;
        flex: 0.55;
        height: 250px;
        border-radius: 10px;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        > .icon {
          padding: 0;
          cursor: pointer;
        }
      }
    }

    > .secondRow {
      display: flex;

      > .right {
        text-align: right;
        flex-shrink: 0;
        flex: 0.45;

        > div:nth-of-type(1) {
          > span:nth-of-type(1) {
            color: #9873ff;
            font-size: 50px;
            font-family: 'CrimsonText-Italic', serif;
          }

          > span:nth-of-type(2) {
            margin-left: 5px;
            font-size: 42px;
            font-family: CrimsonText-Italic, serif;
          }
        }

        > div:nth-of-type(2) {
          font-size: 42px;
          font-family: CrimsonText-Italic, serif;
        }

        > div:nth-of-type(3) {
          font-size: 12px;
        }

        &:first-child {
          display: none;
        }
      }

      > .left {
        flex-shrink: 0;
        flex: 0.55;
        height: 250px;
        border-radius: 10px;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        > .icon {
          padding: 0;

          cursor: pointer;
          width: 100%;
        }
      }
    }

    > .thirdRow {
      display: flex;

      > .left {
        padding-top: 45px;
        flex-shrink: 0;
        flex: 0.45;

        > div:nth-of-type(1) {
          line-height: 50px;
          font-size: 45px;
          font-family: CrimsonText-Italic, serif;
          margin-bottom: 20px;
        }

        > div:nth-of-type(2) {
          font-size: 12px;
        }
      }

      > .right {
        flex-shrink: 0;
        flex: 0.55;
        height: 250px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 10px;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        > .icon {
          padding: 20px 20px 20px 25px;
          background-color: #ffffff;
          border-radius: 35px;
          cursor: pointer;
        }
      }
    }
  }

  .contentEnd {
    margin: auto;
    width: 100%;
    text-align: center;
    background-color: #282136;
    padding: 12px 0px;
    border-radius: 35px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    > span {
      margin: 0 10px;
      font-family: CrimsonText-Italic, serif;
      font-size: 26px;
    }

    > .img {
      object-fit: contain;
    }
  }

  .playInfoContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    aspect-ratio: 16/9;
    background-position: center;
    background-size: contain;

    .playContainer {
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 25px;
      background: var(--app-text-60-color);
      display: grid;
      place-items: center;
      top: calc(50% - 25px);
      left: calc(50% - 25px);
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 660px) {
  .content {
    padding: 0;

    .mainCon {
      > .firstRow {
        display: block;

        > .left {
          display: block;
          text-align: center;
        }

        > .right {
          margin-top: 50px;
          display: block;
        }
      }

      > .secondRow {
        display: block;

        > .left {
          margin-top: 50px;
          display: block;
        }

        > .right {
          text-align: center;

          &:first-child {
            display: block;
          }

          &:last-child {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .content {
    > h2 {
      > div:nth-of-type(1) {
        font-size: 32px;
      }

      margin-bottom: 30px;
    }

    .mainCon {
      > div {
        margin-bottom: 0;
      }

      > .firstRow {
        margin-bottom: 0;

        > .left {
          > div:nth-of-type(1) {
            font-size: 30px;
          }

          > div:nth-of-type(2) {
            font-size: 30px;
          }
        }

        > .right {
          margin-left: 0;
          margin-top: 20px;
        }
      }

      > .secondRow {
        > .left {
          margin-right: 0;
          margin-top: 20px;
        }

        > .right {
          > div:nth-of-type(1) {
            > span:nth-of-type(1) {
              font-size: 30px;
            }

            > span:nth-of-type(2) {
              font-size: 30px;
            }
          }

          > div:nth-of-type(2) {
            font-size: 30px;
          }
        }
      }
    }

    .contentEnd {
      margin: auto;
      width: 100%;
      text-align: center;
      background-color: #282136;
      padding: 12px;
      border-radius: 35px;
      display: flex;
      justify-content: space-evenly;

      > span {
        margin: 0 10px;
        font-family: CrimsonText-Italic, serif;
        font-size: 15px;
      }

      > img {
        object-fit: contain;
        width: 40px;
      }
    }

    .playContainer {
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 25px;
      background: var(--app-text-60-color);
      display: grid;
      place-items: center;
      top: calc(50% - 25px);
      left: calc(50% - 25px);
      cursor: pointer;
      background-size: cover;
      background-position: center;
    }
  }
}

